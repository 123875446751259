import { createStore, applyMiddleware } from 'redux';
// import * as thunk from 'redux-thunk';
import thunkMiddleware from 'redux-thunk';
import invariant from 'redux-immutable-state-invariant';
import { composeWithDevTools } from 'redux-devtools-extension';
import promiseMiddleware from 'redux-promise-middleware';
// eslint-disable-next-line max-len
// import { createReactNavigationReduxMiddleware } from 'react-navigation-redux-helpers';
// import { persistStore } from 'redux-persist'
import reducers from './rootReducer'; // Import the root reducer

// redux navigator
// const navigatorMiddleware = createReactNavigationReduxMiddleware(
//   'root',
//   state => state.nav,
// );

const enhancer = composeWithDevTools(
  applyMiddleware(
    invariant(),
    thunkMiddleware,
    promiseMiddleware,
    // navigatorMiddleware,
  ),
);
const store = createStore(reducers, enhancer);

// persistStore(store, null, () => {
//   store.getState() // if you want to get restoredState
// })

export default store;
