/* eslint-disable react/no-unused-state */
import React from "react"
import { IntlProvider, addLocaleData } from "react-intl"
import en from "react-intl/locale-data/en"
import th from "react-intl/locale-data/th"
import { thTranslation, enTranslation } from "./lang"

// Lodash

addLocaleData([...en, ...th])

const { Provider, Consumer } = React.createContext()

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args)
    this.messages = {
      th: thTranslation,
      en: enTranslation,
    }
    this.switchLanguage = locale => {
      localStorage.setItem("initialLocale", locale)
      return this.setState({ locale })
    }

    this.state = {
      locale: "en",
      switchLanguage: this.switchLanguage,
      messages: this.messages,
    }
  }

  componentDidMount() {
    this.setState({ locale: localStorage.getItem("initialLocale") || "en" })
  }

  render() {
    const { children } = this.props
    const { locale } = this.state
    return (
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={this.messages[locale]}
      >
        <Provider value={this.state}>{children}</Provider>
      </IntlProvider>
    )
  }
}

export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer }
