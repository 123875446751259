export default {
    OBSERVE_AUTH_PENDING: 'OBSERVE_AUTH_PENDING',
    OBSERVE_AUTH_FULFILLED: 'OBSERVE_AUTH_FULFILLED',
    OBSERVE_AUTH_REJECTED: 'OBSERVE_AUTH_REJECTED',
    REGISTER_WITH_FACEBOOK_PENDING: 'REGISTER_WITH_FACEBOOK_PENDING',
    REGISTER_WITH_FACEBOOK_FULFILLED: 'REGISTER_WITH_FACEBOOK_FULFILLED',
    REGISTER_WITH_FACEBOOK_REJECTED: 'REGISTER_WITH_FACEBOOK_REJECTED',
    FETCH_MY_CARDS_SUCCESS: 'FETCH_MY_CARDS_SUCCESS',
    GET_PROFILE: '@API_GET_PROFILE'
  };
  