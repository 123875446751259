// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-my-account-js": () => import("/opt/build/repo/src/pages/MyAccount.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-noti-detail-page-js": () => import("/opt/build/repo/src/pages/NotiDetailPage.js" /* webpackChunkName: "component---src-pages-noti-detail-page-js" */),
  "component---src-pages-qr-code-js": () => import("/opt/build/repo/src/pages/qr-code.js" /* webpackChunkName: "component---src-pages-qr-code-js" */),
  "component---src-pages-survey-js": () => import("/opt/build/repo/src/pages/Survey.js" /* webpackChunkName: "component---src-pages-survey-js" */)
}

