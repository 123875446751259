import typeToReducer from "type-to-reducer"
import TYPES from "./types"

const initialState = {
  data: null,
  getProfileStatus: {},
}

const filterImage = photoURL => {
  if (photoURL.includes("facebook")) {
    if (photoURL.includes("large")) {
      return photoURL
    } else return photoURL + '?type=large"'
  }
  return photoURL
}

const AuthReducer = typeToReducer(
  {
    [TYPES.GET_PROFILE]: {
      PENDING: state => ({
        ...state,
        getProfileStatus: {
          isPending: true,
          error: null,
        },
      }),
      FULFILLED: (state, action) => ({
        ...state,
        data: {
          ...action.payload,
          photoURL: filterImage(action.payload.photoURL),
        },
        getProfileStatus: {
          isFulfilled: true,
          error: null,
        },
      }),
      REJECTED: (state, action) => ({
        ...state,
        getProfileStatus: {
          isRejected: true,
          error: action.payload,
        },
      }),
    },
  },
  initialState
)

export default AuthReducer
