import toArray from "lodash/toArray"

const createTranslation = (LangTranslation, locale) => {
  const key = Object.keys(LangTranslation)
  const language = {}
  toArray(LangTranslation).forEach((lang, index) => {
    language[key[index]] = lang[locale]
  })
  return language
}

const LangTranslation = {
  Hello: {
    en: "Hello",
    th: "สวัสดี",
  },
  OurMenu: {
    en: "Our Menu",
    th: "เมนูของเรา",
  },
  StoreLocator: {
    en: "Store Locator",
    th: "ที่ตั้งร้านค้า",
  },
}

export const thTranslation = createTranslation(LangTranslation, "th")
export const enTranslation = createTranslation(LangTranslation, "en")
