import { combineReducers } from "redux"

import authReducer from "../modules/auth/reducer"
import notificationReducer from "../modules/notification/reducer"
import brandReducer from "../modules/brand/reducer"
import rewardReducer from "../modules/reward/reducer"

export default combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  brand: brandReducer,
  reward: rewardReducer,
})
